import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF} from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./index.scss"
import "./kontakt.scss"

const Kontakt = () => (

	<Layout>
		<Seo title="Kontakt" description="Kontakt na taneční klub STK Praha" />

		<div className="stk-background stk-background--kontakt"></div>

		<div className="stk-container stk-container--top-margin mb-5">
			<h1 className="stk-h1">STK Praha<br />sportovní taneční klub</h1>
			<Row>
				<Col xs="12" lg="4" xl="3">
					<h2 className="stk-h2 mt-5 mb-3">Jiří Pixa</h2>
					<p className="mb-2">
						<a href="tel:+420602387109"><FontAwesomeIcon icon={faPhone} /> +420 602 387 109</a>
					</p>
					<p>
						<a href="mailto:jj.pixa@seznam.cz"><FontAwesomeIcon icon={faEnvelope} /> jj.pixa@seznam.cz</a>
					</p>
					<p>
						Budova Casablanca<br />
						Vinohradská 184<br />
						130 00&nbsp;&nbsp;Praha 3 - Vinohrady
					</p>
					<a className="stk-button stk-button--small mb-2" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
				</Col>
				<Col xs="12" lg="4" xl="3">
					<h2 className="stk-h2 mt-5 mb-3">Jiří Ševčík</h2>
					<p className="mb-2">
						<a href="tel:+420777027703"><FontAwesomeIcon icon={faPhone} /> +420 777 027 703</a>
					</p>
					<p>
						<a href="mailto:info@bohemiadance.cz"><FontAwesomeIcon icon={faEnvelope} /> info@bohemiadance.cz</a>
					</p>
					<p>
						Bohemia Dance<br />
						U Měšťanského pivovaru 934/4<br />
						170 00&nbsp;&nbsp;Praha 7 - Holešovice
					</p>
					<a className="stk-button stk-button--small mb-2" href="https://goo.gl/maps/N9xjzfimkU9hR4ng8" target="_blank" rel="noreferrer">zobrazit na mapě</a>
				</Col>
				<Col xs="12" lg="2" xl="2">
					<div className="mt-5 mb-5">
						<a className="stk-socialicon stk-socialicon--facebook" href="https://www.facebook.com/stkpraha" target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faFacebookF} />
						</a>
					</div>
				</Col>
			</Row>
		</div>

	</Layout>

)

export default Kontakt